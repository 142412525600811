<template>
  <div>
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default() {
        return "rrrrr";
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    series() {
      const costPerDay = this.items?.total_cost_per_day;
      const expPerDay = this.items?.total_expense_per_day;
      if (costPerDay || expPerDay) {
        return [
          {
            name: "Revenue",
            type: "line",
            data: costPerDay.map((r) => ({
              x: `${this.$options.filters.formatDate(r.date, "DD-MMM-YY")}`,
              y: r.total_cost,
            })),
          },
          {
            name: "Expense",
            type: "line",
            data: expPerDay.map((r) => ({
              x: `${this.$options.filters.formatDate(r.date, "DD-MMM-YY")}`,
              y: r.total_cost,
            })),
          },
        ];
      } else {
        return [];
      }
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "solid",
          opacity: [0.35, 1],
          colors: ["#ffd4d4"],
        },
        colors: ["#20a390", "#fb7185"], // Custom colors for Series A & Series B
        // labels: dateTime,
        markers: {
          size: 0,
        },
        yaxis: [
          {
            title: {
              text: "PKR",
            },
            // labels: {
            //   formatter: function (value) {
            //     return value.toFixed(2); // Format with commas
            //   },
            // },
          },

          // {
          //   // opposite: true,
          //   title: {
          //     text: "Series B",
          //   },
          // },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " PKR";
              }
              return y;
            },
          },
        },
      };
    },
  },
};
</script>
